import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { OrderPackage } from '../_models';

@Injectable()
export class OrderPackageService {
    constructor(private http: HttpClient) { }

    async getById(id: any) {
        return this.http.get<OrderPackage>(environment.apiUrl + 'orderpackages/' + id).toPromise();
    }

    async getByPackIdAndCompanyId(packId: any, companyId: any) {
        return this.http.get<OrderPackage>(environment.apiUrl
            + "orderpackages?company_id=" + companyId + "&pack_id=" + packId).toPromise();
    }

    async getByFilter(filter: any) {
        return await this.http.post<any[]>(environment.apiUrl + 'orderpackages/filter', filter).toPromise();
    }
    async postOrderPackage(bodyOrderpackage: any) {
        return await this.http.post<any[]>(environment.apiUrl + 'orderpackages', bodyOrderpackage).toPromise();
    }

    async getCountByFilter(filter: any) {
        return await this.http.post<any>(environment.apiUrl + 'orderpackages/count', filter).toPromise();
    }

    async solve(id: any){
        return await this.http.patch<any[]>(environment.apiUrl + 'orderpackages/' + id, {'conversationStatus': "SOLVED"}).toPromise();
    }
    async editOrderPackage(id: number, bodyOrderpackage: any){
        return await this.http.put<any[]>(environment.apiUrl + 'orderpackages/' + id, bodyOrderpackage).toPromise();
    }

    async addPendency(id: any){
        return await this.http.patch<any[]>(environment.apiUrl + 'orderpackages/' + id, {'conversationStatus': "PENDING"}).toPromise();
    }

    async deleteItemOrderPackage(idOrderPack: any, orderId: any, itemId: any){
        return await this.http.delete<any[]>(environment.apiUrl + 'orderpackages/' + idOrderPack +'/orders/' + orderId +'/items/' + itemId).toPromise();
    }
    async editItemOrderPackage(idOrderPack: any, orderId: any, itemId: any, bodyOrder: any){
        return await this.http.put<any[]>(environment.apiUrl + 'orderpackages/' + idOrderPack +'/orders/' + orderId +'/items/' + itemId, bodyOrder).toPromise();
    }

    async saveItemOrderPackage(idOrderPack: any, orderId: any, bodyOrder: any[]){
      return await this.http.post<any[]>(environment.apiUrl + 'orderpackages/' + idOrderPack +'/orders/' + orderId +'/items/', bodyOrder).toPromise();
    }

    async createInvoiceFromOrderPackage(orderPackageId: any) {
        return await this.http.post<any>(environment.apiUrl + 'orderpackages/' + orderPackageId +'/invoices', null).toPromise();
    }

    downloadShipmentLabel(id: any) {
        const httpOptions = {
            responseType: 'blob' as 'json'
          };
        return this.http.get<any>(
            environment.apiUrl + "orderpackages/" + id + "/shipmentlabel", httpOptions).toPromise()
            .catch(async (error) => {
                if (error.error instanceof Blob) {
                  const errorText = await error.error.text();
                  try {
                    const jsonError = JSON.parse(errorText);
                    error.error = jsonError;
                  } catch (e) {
                    console.error('Erro ao interpretar JSON do erro', e);
                  }
                }
                throw error;
              });
    }

    downloadShipmentLabels(ids: any[]) {
        const httpOptions = {
            responseType: 'blob' as 'json'
          };
        return this.http.get<any>(
            environment.apiUrl + "orderpackages/shipmentlabel?ids=" + ids, httpOptions);
    }
}
